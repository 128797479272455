import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import { PackagedHoneyHeroContent } from "./PackagedHoneyHeroContent";
import "./styles.css";

const PackagedHoneyHero = ({ background, alt, subtitle, title, description }) => {
  return (
    <div className="PackagedHoneyHero">
      <div className="[ Content ] [ PackagedHoneyHero__container ]">
        <PackagedHoneyHeroContent
          className="PackagedHoneyHero__content"
          subtitle={subtitle}
          title={title}
          description={description}
        />

        <div className="PackagedHoneyHero__image">
          <Image image={background} alt={alt} />
        </div>
      </div>
    </div>
  );
};

PackagedHoneyHero.getLivePreviewData = ({ data, getAsset }) => ({
  packagedHoneyHero: {
    ...data.packagedHoneyHero,
    background: getAsset(data.packagedHoneyHero.background).url,
  },
});

const packagedHoneyHeroQuery = graphql`
  fragment PackagedHoneyHeroFragment on MarkdownRemarkFrontmatter {
    packagedHoneyHero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      description
    }
  }
`;

export { PackagedHoneyHero, packagedHoneyHeroQuery };
