import React from "react";

const Hexagon = ({ className }) => {
  return (
    <svg className={className} width="106" height="100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g style={{ mixBlendMode: "multiply" }} opacity=".15" filter="url(#filter0_d)">
        <path
          d="M48.676 42H31.269a8 8 0 00-6.907 3.964l-8.036 13.752a8 8 0 00-.047 7.99l8.099 14.247A8 8 0 0031.333 86h17.279a8 8 0 006.946-4.03L63.7 67.72a8 8 0 00-.048-8.021l-8.079-13.752A8 8 0 0048.676 42z"
          fill="url(#paint0_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="43.534"
          y1="45.28"
          x2="43.534"
          y2="93.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A326AE" />
          <stop offset="1" stop-color="#A326AE" stop-opacity="0" />
        </linearGradient>
        <filter
          id="filter0_d"
          x=".233"
          y="0"
          width="105.521"
          height="100"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="13" dy="-14" />
          <feGaussianBlur stdDeviation="14" />
          <feColorMatrix values="0 0 0 0 0.640511 0 0 0 0 0.148056 0 0 0 0 0.683333 0 0 0 0.29 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export { Hexagon };
