import React from "react";
import cn from "classnames";
import { Hexagon } from "../../ClassicSyrupsHero/Hexagon";
import "./styles.css";

const PackagedHoneyHeroContent = ({ className, subtitle, title, description }) => {
  return (
    <div className={cn(className, "PackagedHoneyHeroContent")}>
      <p className="Font_smallHeaderCaps sm:Font_mediumTextCaps Color_lightGrey">{subtitle}</p>
      <h1 className="[ PackagedHoneyHeroContent__title ] [ Font_bigHeaderSerif Color_black ]">{title}</h1>
      <div className="PackagedHoneyHeroContent__descriptionContainer">
        <Hexagon className="PackagedHoneyHeroContent__hexagon" />
        <p className="[ PackagedHoneyHeroContent__description ] [ Font_regularText Color_darkGrey ]">{description}</p>
      </div>
    </div>
  );
};

export { PackagedHoneyHeroContent };
