import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { PackagedHoneyAdvantages } from "../components/PackagedHoneyAdvantages";
import { PackagedHoneyHero } from "../components/PackagedHoneyHero";
import { PackagedHoneyOtherProducts } from "../components/PackagedHoneyOtherProducts";

const PackagedHoneyPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <PackagedHoneyHero {...props.packagedHoneyHero} />
      <PackagedHoneyAdvantages formBody={layout.formBody} {...props.packagedHoneyAdvantages} />
      <PackagedHoneyOtherProducts {...props.packagedHoneyOtherProducts} />
    </>
  );
};

PackagedHoneyPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...PackagedHoneyHero.getLivePreviewData(props),
  ...PackagedHoneyAdvantages.getLivePreviewData(props),
  ...PackagedHoneyOtherProducts.getLivePreviewData(props),
});

const PackagedHoneyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout {...data.layout.frontmatter}>
      <PackagedHoneyPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query PackagedHoneyPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "packaged-honey" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }

        ...PackagedHoneyHeroFragment
        ...PackagedHoneyAdvantagesFragment
        ...PackagedHoneyOtherProductsFragment
      }
    }
  }
`;

export default PackagedHoneyPage;

export { pageQuery, PackagedHoneyPageTemplate };
