import { Link } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const OtherProducts = ({ title, products }) => {
  return (
    <div className="OtherProducts__background">
      <div className="Content">
        <h2 className="[ OtherProducts__title ] [ Font_headerSerif ]">{title}</h2>
        <ul className="OtherProducts__list">
          {products.map(product => (
            <Link to={product.href} className="[ OtherProducts__card ] [ Color_black ]">
              <li key={product.title}>
                <Image className="OtherProducts__cardImage" image={product.image} alt={product.alt} />
                <p className="[ OtherProducts__cardTitle ] [ Font_smallHeader ]">{product.title}</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { OtherProducts };
