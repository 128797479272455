import { graphql } from "gatsby";
import React from "react";
import { Form } from "../Form";
import { ContactUs } from "../ContactUs";
import "./styles.css";

const PackagedHoneyAdvantages = ({ advantages, formHeader, formBody }) => {
  return (
    <ContactUs
      className="PackagedHoneyAdvantages"
      advantages={advantages}
      formHeader={formHeader}
      formBody={formBody}
    />
  );
};

PackagedHoneyAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  packagedHoneyAdvantages: {
    ...data.packagedHoneyAdvantages,
  },
});

const packagedHoneyAdvantagesQuery = graphql`
  fragment PackagedHoneyAdvantagesFragment on MarkdownRemarkFrontmatter {
    packagedHoneyAdvantages {
      advantages {
        title
        text
      }
      formHeader {
        title
        text
      }
    }
  }
`;

export { PackagedHoneyAdvantages, packagedHoneyAdvantagesQuery };
