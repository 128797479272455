import { graphql } from "gatsby";
import React from "react";
import { OtherProducts } from "../OtherProducts";
import "./styles.css";

const PackagedHoneyOtherProducts = ({ title, products }) => {
  products = [
    { ...products[0], href: "/professional-syrups" },
    { ...products[1], href: "/classic-syrups" },
    { ...products[2], href: "/honey" },
  ];

  return <OtherProducts title={title} products={products} />;
};

PackagedHoneyOtherProducts.getLivePreviewData = ({ data, getAsset }) => ({
  packagedHoneyOtherProducts: {
    ...data.packagedHoneyOtherProducts,
    products: {
      ...data.packagedHoneyOtherProducts.products,
      image: getAsset(data.packagedHoneyOtherProducts.products.image).url,
    },
  },
});

const packagedHoneyOtherProductsQuery = graphql`
  fragment PackagedHoneyOtherProductsFragment on MarkdownRemarkFrontmatter {
    packagedHoneyOtherProducts {
      title
      products {
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        alt
      }
    }
  }
`;

export { PackagedHoneyOtherProducts, packagedHoneyOtherProductsQuery };
